import Vue from 'vue'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import vueSmoothScroll from 'vue2-smooth-scroll'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './registerServiceWorker'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  let language = to.params.lang
  if (!language) {
    language = 'charset'
  }
  i18n.locale = language
  next()
})

Vue.use(VueMeta, {
  attribute: 'meta-id'
})
Vue.use(Vuelidate)
Vue.use(vueSmoothScroll)

new Vue({
  router,
  store,
  i18n,
  created () {
    window.addEventListener('resize', e => {
      this.$store.commit('changeWindowWidth')
    })
  },
  render: h => h(App)
}).$mount('#app')
