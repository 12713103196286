<template>
  <main id="app" class="main">
    <comp-header/>
    <div class="content">
      <router-view/>
    </div>
    <comp-social :isMobile="0" />
    <comp-btntop/>
    <comp-footer/>
    <comp-loader/>
  </main>
</template>

<style lang="scss">
  @import '../node_modules/bootstrap/scss/bootstrap-grid';
</style>
<style lang="scss">
  @import 'assets/styles/sass/styles.scss';
</style>
<script>
import CompHeader from '@/components/Header'
import CompSocial from '@/components/SocialLinks'
import CompBtntop from '@/components/Btntop'
import CompFooter from '@/components/Footer'
import CompLoader from '@/components/Loader'
export default {
  components: {
    CompHeader, CompFooter, CompLoader, CompSocial, CompBtntop
  },
  metaInfo () {
    const meta = this.$t('meta.hp')
    return {
      title: 'Virarosi',
      meta: [
        { property: 'og:title', content: 'Virarosi' },
        { property: 'og:description', content: meta.description }
      ]
    }
  }
}
</script>
