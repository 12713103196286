<template>
  <div class="scrl">
      <a href="#sec-1" v-smooth-scroll="{ duration: 1000, offset: -130 }"
         class="scrl__btn" :class="[{'fixed': isFixed}, {'visible': isVisible}]">
        <img src="/img/icons/ui/ico-any-arrow-top-white.svg" alt="arrow">
      </a>
  </div>
</template>
<script>
export default {
  data: () => ({
    isVisible: false,
    isFixed: true
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const footer = document.getElementsByClassName('footer')[0].offsetTop
      const windowHeight = window.innerHeight
      const offset = document.documentElement.scrollTop + windowHeight
      this.isVisible = (offset - windowHeight) >= windowHeight
      this.isFixed = !(offset >= footer)
    }
  }
}
</script>
