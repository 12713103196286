<template>
  <div class="social" v-if="($i18n.locale === 'cs' || $i18n.locale === 'en')">
      <div class="social__wrapper" :class="[{'fixed': isFixed}, socCls[isMobile]]">
        <a class="social__item" v-for="(link, code) in links[locale]" :key="'soc-item-' + code"
           :href="link" :class="'social__item--' + code" target="_blank"></a>
      </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    social: ['fb', 'ig', 'wa', 'tw'],
    links: {
      en: {
        fb: 'https://www.facebook.com/Virarosi-English-106678101756588',
        ig: 'https://www.instagram.com/virarosi_en/',
        tw: 'https://twitter.com/virarosi_en'
      },
      es: {},
      cs: {
        fb: 'https://www.facebook.com/Virarosi-111625501117128/',
        ig: 'https://www.instagram.com/virarosi_cs/',
        tw: 'https://twitter.com/virarosi_cs'
      },
      ru: {},
      vi: {}
    },
    socCls: ['vertical', 'horizontal'],
    isFixed: true
  }),
  props: {
    isMobile: {
      required: true,
      type: Number
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    isInObj (code) {
      return code in this.links[this.locale]
    },
    itemHref (code) {
      let res = this.links.en[code]
      if (code in this.links[this.locale]) res = this.links[this.locale][code]
      return res
    },
    handleScroll () {
      const footer = document.getElementsByClassName('footer')[0].offsetTop + (window.innerHeight / 2) - (138 / 2) - 15
      const offset = document.documentElement.scrollTop + window.innerHeight
      this.isFixed = !(offset >= footer)
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  }
}
</script>
