<template>
  <footer class="footer">
    <div class="container">
      <div class="row mb-4 mb-md-5">
        <div class="col-12">
          <div class="image image--logo">
            <img src="/img/logo/logo-b.svg" alt="logo">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4 mb-4 mb-lg-0">
          <div class="footer__info">
            <div class="footer__info-item mb-3 mb-md-4">
              <div class="ico ico--footer ico--footer-waypoint">
                <img src="/img/icons/ico-foot-waypoint.svg" alt="Address:">
              </div>
              <p class="text fs-12 fs-md-14 fw-500 lh-20">{{ cc.info.address }}</p>
            </div>
            <div class="footer__info-item mb-3 mb-md-4">
              <div class="ico ico--footer ico--footer-phone">
                <img src="/img/icons/ico-foot-phone.svg" alt="Tel:">
              </div>
              <a :href="`tel:${cc.info.phone.link}`" class="link fs-12 fs-md-14">
                {{ cc.info.phone.text }}
              </a>
            </div>
            <div class="footer__info-item mb-3 mb-md-4">
              <div class="ico ico--footer ico--footer-phone">
                <img src="/img/icons/ico-foot-phone.svg" alt="Tel:">
              </div>
              <a :href="`tel:${cc.info.phone2.link}`" class="link fs-12 fs-md-14">
                {{ cc.info.phone2.text }}
              </a>
            </div>
            <div class="footer__info-item">
              <div class="ico ico--footer ico--footer-mail">
                <img src="/img/icons/ico-foot-mail.svg" alt="Email:">
              </div>
              <a href="mailto:support@virarosi.com" class="link fs-12 fs-md-14">support@virarosi.com</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <p class="text tc-silver fs-10 fs-md-12 lh-20">{{ $t('footer.text.disclaimer') }}</p>
        </div>
      </div>
      <div class="row mt-4 mt-md-5 mb-3 mb-md-4">
        <div class="col-12">
          <hr class="footer__line"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8 col-xl-8">
          <div class="footer__routes">
            <router-link tag="a" :to="`/${$i18n.locale}` + $t('router.' + item.key)" class="link link--td-none tc-silver fs-12 fs-md-14 fw-500"
                         v-for="(item, i) in $t('footer.links')" :key="'footer-link-' + i">
              {{ item.name }}
            </router-link>
          </div>
        </div>
        <div class="col-12 mt-2 mt-sm-3 mb-3 mb-md-4 d-md-none">
          <hr class="footer__line"/>
        </div>
        <div class="col-12 col-md-4">
          <div class="d-flex justify-content-start justify-content-md-end">
            <p class="text tc-silver fs-10 fs-md-12">
              {{ $t('footer.text.rights')[0] + ' ' + getYear() + ' ' +$t('footer.text.rights')[1] }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data: () => ({
    date: new Date()
  }),
  computed: {
    cc () {
      return this.$t('footer')
    }
  },
  methods: {
    getYear () {
      return this.date.getFullYear()
    }
  }
}
</script>
