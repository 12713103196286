import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/instructions',
    redirect: `/${i18n.locale}/account/deposit`
  },
  {
    path: '/:lang(en|es|ru|vi|cs|pl)',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home')
      },
      {
        path: 'brand',
        name: 'Brand',
        component: () => import('../views/Brand')
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/About')
      },
      {
        path: 'careers',
        name: 'Careers',
        component: () => import('../views/Careers')
      },
      {
        path: 'careers/vacancy',
        name: 'Vacancy',
        component: () => import('../views/Vacancy')
      },
      {
        path: 'account',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'verify',
            name: 'Verify',
            component: () => import('../views/accounts/Verify')
          },
          {
            path: 'deposit',
            name: 'Deposit',
            component: () => import('../views/accounts/Deposit')
          }
        ]
      },
      {
        path: 'partners',
        name: 'Partners',
        component: () => import('../views/partners/Partners')
      },
      {
        path: 'bidaskbit',
        name: 'BidAskBit',
        component: () => import('../views/partners/Bidaskbit')
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import('../views/Blog')
      },
      {
        path: 'blog/article',
        redirect: `/${i18n.locale}/blog`
      },
      {
        path: 'blog/article/:id',
        name: 'Article',
        component: () => import('../views/Article')
      },
      {
        path: 'documents',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'terms-and-conditions',
            name: 'Terms & Conditions',
            component: () => import('../views/documents/TermsAndConditions')
          },
          {
            path: 'aml-and-kyc',
            name: 'AML & KYC',
            component: () => import('../views/documents/AmlAndKyc')
          },
          {
            path: 'privacy-policy',
            name: 'Privacy Policy',
            component: () => import('../views/documents/PrivacyPolicy')
          }
        ]
      },
      {
        path: '/*',
        name: '404',
        meta: {
          layout: 'default'
        },
        component: () => import('../views/404.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let firstLoad = true
function defaultLanguage () {
  const supportedLanguages = ['en', 'cs', 'es', 'ru', 'vi', 'pl']
  const browserLang = window.navigator.language.split('-')[0]
  let defaultLang = 'en'
  if (supportedLanguages.includes(browserLang)) {
    defaultLang = browserLang
  }
  return defaultLang
}

router.beforeResolve((to, from, next) => {
  const loader = document.getElementById('loader').classList
  if (to.name !== from.name) {
    loader.add('active')
    setTimeout(() => {
      next()
    }, 200)
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  const loader = document.getElementById('loader').classList
  // const waiting = firstLoad ? 1000 : 200
  const waiting = firstLoad ? 500 : 200
  const defLang = defaultLanguage()
  const params = window.location.href.split('?')[1]
  if (!store.state.params) store.state.params = params
  if (firstLoad && defLang !== to.params.lang) {
    router.push({
      path: params ? `/${defLang}?${params}` : `/${defLang}/`
    })
  }
  firstLoad = false
  setTimeout(() => {
    loader.remove('active')
  }, waiting)
})

export default router
