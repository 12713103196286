import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    params: null,
    vacancy: 'none',
    ww: window.innerWidth,
    isNavToggle: false,
    isLanguageSwitcherToggle: false,
    months: {
      en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      cs: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
      ru: ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
      vi: ['Tháng một', 'Tháng hai', 'Tháng ba', 'Tháng tư', 'Tháng năm', 'Tháng sáu', 'Tháng bảy', 'Tháng tám', 'Tháng chín', 'Tháng mười', 'Tháng mười một', 'Tháng mười hai']
    },
    hash: ['btc', 'eth', 'ltc', 'bch'],
    widgetData: {
      btc: {
        fullName: 'Bitcoin',
        shortName: 'btc',
        toEur: {
          prev: '',
          curr: '',
          diff: ''
        },
        toUsd: {
          curr: ''
        },
        fromEur: '',
        status: ''
      },
      eth: {
        fullName: 'Ethereum',
        shortName: 'eth',
        toEur: {
          prev: '',
          curr: '',
          diff: ''
        },
        toUsd: {
          curr: ''
        },
        fromEur: '',
        status: ''
      },
      ltc: {
        fullName: 'Litecoin',
        shortName: 'ltc',
        toEur: {
          prev: '',
          curr: '',
          diff: ''
        },
        toUsd: {
          curr: ''
        },
        fromEur: '',
        status: ''
      },
      bch: {
        fullName: 'Bitcoin Cash',
        shortName: 'bch',
        toEur: {
          prev: '',
          curr: '',
          diff: ''
        },
        toUsd: {
          curr: ''
        },
        fromEur: '',
        status: ''
      }
    },
    ipCountry: null,
    blackList: ['GB', 'CY', 'LT', 'LV', 'MT', 'EE'],
    firstLoad: true
  },
  mutations: {
    changeWindowWidth (state) {
      state.ww = window.innerWidth
    },
    getCryptoData (state) {
      fetch('https://client.virarosi.com/datafeed/exchange-rate-pair')
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('Network response was not ok')
        })
        .then((json) => {
          setData(json)
        })
        .catch((error) => {
          console.log(error)
        })

      function setData (json) {
        let prevItem = null
        let divider = null
        let bigger = null
        let smaller = null
        let randomSelect = null
        let diff = null
        for (const elem in json) {
          state.hash.forEach((item) => {
            if (elem.includes(item)) {
              const cryptoEur = json[item + 'eur']
              const cryptoUsd = json[item + 'usd']
              const eurCrypto = json['eur' + item]
              if (state.firstLoad) {
                if (prevItem !== item) {
                  prevItem = item
                  divider = (Math.random() * (1000 - 10000) + 10000).toFixed(8)
                  bigger = Number(cryptoEur) + Number(cryptoEur / divider)
                  smaller = Number(cryptoEur) - Number(cryptoEur / divider)
                  randomSelect = Math.random() > 0.5 ? smaller : bigger
                  diff = cropValue(getDiff(Number(cryptoEur), randomSelect))
                  state.widgetData[item].toEur.prev = randomSelect
                  state.widgetData[item].toEur.curr = cryptoEur
                  state.widgetData[item].toUsd.curr = cryptoUsd
                  state.widgetData[item].fromEur = eurCrypto
                  state.widgetData[item].status = cryptoEur > randomSelect ? 'up' : 'down'
                  state.widgetData[item].toEur.diff = cryptoEur > randomSelect ? '+' + diff : '-' + diff
                }
              } else {
                if (prevItem !== item) {
                  if (state.widgetData[item].toEur.curr !== cryptoEur) {
                    prevItem = item
                    const prevCost = state.widgetData[item].toEur.prev
                    diff = cropValue(getDiff(Number(cryptoEur), prevCost))
                    state.widgetData[item].toEur.prev = state.widgetData[item].toEur.curr
                    state.widgetData[item].toEur.curr = cryptoEur
                    state.widgetData[item].toUsd.curr = cryptoUsd
                    state.widgetData[item].fromEur = eurCrypto
                    state.widgetData[item].status = cryptoEur > prevCost ? 'up' : 'down'
                    state.widgetData[item].toEur.diff = cryptoEur > prevCost ? '+' + diff : '-' + diff
                  }
                }
              }
            }
          })
        }
        state.firstLoad = false
      }
      function getDiff (a, b) {
        return 100 * Math.abs((a - b) / ((a + b) / 2))
      }
      function cropValue (val) {
        let resCost = val.toFixed(2)
        if (resCost.split('.')[1].split('')[1] === '0') {
          resCost = '0.01'
        }
        return resCost
      }
    },
    getIpCointry (state) {
      fetch('/vendor/scripts/ip/getInfo.php').then(
        (response) => response.json()
      ).then(
        (json) => { state.ipCountry = json.country }
      )
    }
  },
  actions: {
  },
  modules: {
  }
})
