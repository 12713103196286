<template>
  <header class="header" :class="{'active': isNavToggle}">
    <div class="header__container">
      <div class="header__row">
        <div class="header__group header__group--left">
          <router-link tag="div" :to="`/${$i18n.locale}`" class="img img--logo" :class="{'active': isNavToggle}">
            <img src="/img/logo/logo-b.svg" alt="logo">
            <img src="/img/logo/logo-w.svg" alt="logo">
          </router-link>
          <nav class="nav nav--desktop">
            <div class="nav__item" v-for="(item, i) in cc.links" :key="'nav-item-' + i">
              <template v-if=" 'key' in item ">
                <router-link tag="a" :to="`/${$i18n.locale}` + $t('router.' + item.key)" class="nav__link">
                  {{ item.name }}
                </router-link>
              </template>
              <template v-else>
                <div class="nav__exp">
                  <p class="nav__exp-head">
                    <span>{{ item.name }}</span>
                    <i class="nav__exp-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13.478" height="7.028" viewBox="0 0 13.478 7.028"><path d="M6,9l5.328,4.618L16.656,9" transform="translate(-4.589 -7.589)" fill="none" stroke="#858a8f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                    </i>
                  </p>
                  <div class="nav__exp-body">
                    <router-link tag="a" :to="`/${$i18n.locale}` + $t('router.' + subItem.key)" class="nav__exp-link"
                                 v-for="(subItem, x) in expSort(item.sub)" :key="'nav-exp-item-' + x">
                      {{ subItem.name }}
                    </router-link>
                  </div>
                </div>
              </template>
            </div>
          </nav>
        </div>
        <div class="header__group header__group--right">
          <language-switcher/>
          <src-external to="log" class="btn btn-1-blue btn-w-140 btn-h-52 me-3">
            <span>{{ cc.btn[0].text }}</span>
          </src-external>
          <src-external to="reg" class="btn btn-3-blue btn-w-140 btn-h-52">
            <span>{{ cc.btn[1].text }}</span>
          </src-external>
        </div>
        <div class="header__toggle">
          <div class="toggle" :class="{'active': isNavToggle}">
            <label class="toggle__cover" @click="toggle()"></label>
            <input type="checkbox" class="toggle__checkbox" v-model="isNavToggle" />
            <div class="toggle__button">
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header__mobile" :class="{'active': isNavToggle}">
      <div class="header__mobile-scroll">
        <div class="header__mobile-head">
          <language-switcher-mobile :mobile="true"/>
        </div>
        <hr class="header__line" />
        <nav class="nav nav--mobile">
          <div class="nav__item" v-for="(item, i) in cc.links" :key="'nav-item-' + i">
            <template v-if=" 'key' in item ">
              <router-link tag="a" :to="`/${$i18n.locale}` + $t('router.' + item.key)" v-on:click.native="toggle()" class="nav__link">
                {{ item.name }}
              </router-link>
            </template>
            <template v-else>
              <div class="nav__exp">
                <input class="nav__exp-checkbox" type="checkbox" :id="'exp-' + i">
                <label class="nav__exp-head" :for="'exp-' + i">
                  <span>{{ item.name }}</span>
                  <i class="nav__exp-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.478" height="7.028" viewBox="0 0 13.478 7.028"><path d="M6,9l5.328,4.618L16.656,9" transform="translate(-4.589 -7.589)" fill="none" stroke="#858a8f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                  </i>
                </label>
                <div class="nav__exp-body">
                  <router-link tag="a" :to="`/${$i18n.locale}` + $t('router.' + subItem.key)" v-on:click.native="toggle()" class="nav__exp-link"
                               v-for="(subItem, x) in expSort(item.sub)" :key="'nav-exp-item-' + x">
                    {{ subItem.name }}
                  </router-link>
                </div>
              </div>
            </template>
          </div>
        </nav>
        <hr class="header__line" />
        <div class="header__mobile-buttons">
          <src-external to="log" class="btn btn-3-white btn-h-52 me-3">
            <span>{{ cc.btn[0].text }}</span>
          </src-external>
          <src-external to="reg" class="btn btn-3-white btn-h-52">
            <span>{{ cc.btn[1].text }}</span>
          </src-external>
        </div>
        <div class="header__social-buttons">
          <comp-social :isMobile="1" />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import LanguageSwitcher from '@/components/LanguageSwitcher'
import LanguageSwitcherMobile from '@/components/LanguageSwitcherMobile'
import CompSocial from '@/components/SocialLinks'
import SrcExternal from '@/components/SrcExternal'
export default {
  name: 'Header',
  components: {
    LanguageSwitcher, CompSocial, LanguageSwitcherMobile, SrcExternal
  },
  computed: {
    cc () {
      return this.$t('header')
    },
    isNavToggle () {
      return this.$store.state.isNavToggle
    },
    locale () {
      let res = this.$i18n.locale
      res = (res === 'en' || res === 'es') ? '/' : '/' + res + '/'
      return res
    }
  },
  methods: {
    toggle () {
      this.$store.state.isNavToggle = !this.$store.state.isNavToggle
    },
    expSort (arr) {
      const list = this.$store.state.blackList
      const country = this.$store.state.ipCountry || ''
      arr.forEach((item, i) => {
        if (item.key === 'bidaskbit' && list.includes(country)) {
          arr.splice(i, 1)
        }
      })
      return arr
    }
  },
  beforeCreate () {
    this.$store.commit('getIpCointry')
  }
}
</script>
