<template>
  <a :href="link" @click.prevent="openLink(link)">
    <slot></slot>
  </a>
</template>
<script>
export default {
  props: {
    to: {
      required: true
    }
  },
  computed: {
    routes () {
      return this.$t('routes.external')
    },
    link () {
      // const params = window.location.href.split('?')[1]
      const params = this.$store.state.params
      let src = this.routes[this.to]
      if (Array.isArray(src)) {
        src = `${src[0]}${this.$i18n.locale}${src[1]}`
      } else { src = `${src}${this.$i18n.locale}` }
      if (params) src = `${src}?${params}`
      return src
    }
  },
  methods: {
    openLink (v) {
      window.open(v, '_blank')
    }
  }
}
</script>
