<template>
  <div class="switcher switcher--desktop">
    <div class="switcher__head">
      <i class="switcher__icon switcher__icon--flag" :class="'switcher__icon--flag-' + $i18n.locale"></i>
      <span>{{ $i18n.locale }}</span>
      <i class="switcher__icon switcher__icon--arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="13.478" height="7.028" viewBox="0 0 13.478 7.028"><path d="M6,9l5.328,4.618L16.656,9" transform="translate(-4.589 -7.589)" fill="none" stroke="#858a8f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
      </i>
    </div>
    <div class="switcher__body">
      <div class="switcher__wrapper">
        <p class="switcher__item"
           v-for="(lang, i) in $i18n.availableLocales"
           :key="`lang-${i}`"
           @click.prevent="setLocale(lang)"
        >
          <i class="switcher__icon switcher__icon--flag"
             :class="'switcher__icon--flag-' + lang"></i>
          {{ language[lang] }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    language: {
      en: 'English',
      cs: 'Čeština',
      es: 'Español',
      vi: 'Tiếng Việt',
      pl: 'Polski',
      ru: 'Русский'
    }
  }),
  methods: {
    setLocale (locale) {
      this.$i18n.locale = locale
      this.$store.state.isNavToggle = false
      this.$router.push({
        params: { lang: locale }
      })
    }
  }
}
</script>
